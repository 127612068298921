import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createHead } from "@vueuse/head"

import VueMobileDetection from "vue-mobile-detection";

import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import { createPinia } from 'pinia';

const app = createApp(App);
const head = createHead()

import 'animate.css';

app.use(router)
app.use(BootstrapVue3)
app.use(createPinia())
app.use(head)
app.use(VueMobileDetection);





app.mount('#app')



// ! Aquí indicaremos la versión actual de la plataforma
/**
 * Historial de versiones {@link currentVersion}
 * * v.1.0.0 -> 16/11/2022 -> Versión inicial de producción
 * * v.1.1.0 -> 02/12/2022 -> Adaptación responsive + funcionalidad foto víctima
 * * v.1.1.1 -> 03/02/2023 -> Correcciones ortográficas
 * * v.1.1.2 -> 07/03/2023 -> Se añade la columna BIO_SEXO en la tabla campos
 * * v.1.2.0 -> 09/03/2023 -> Implementación de los filtros de campos nazis
 * * v.1.3.0 -> 05/09/2023 -> Cambio de filtros, nueva vista de la tabla del censo, nueva ficha del censo
 * * v.1.3.1 -> 11/09/2023 -> Correcciones menores y actualización de lista de campos nazis en el filtro
 * * v.1.3.2 -> 27/03/2024 -> Corrección que impedía guardar datos en base de datos con el apostrofe «'», por ejemplo L'alcúdia; Se ha bajado el botón de ayuda junto al de búsqueda avanzada.
 */
export const currentVersion = "v1.3.2";


